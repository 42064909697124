import {combineReducers} from '@reduxjs/toolkit'
import {schedule} from './schedule'
import {event} from './event'
import {modals} from './modals'
import {filter} from './filter'
import {error} from './error'

export const rootReducer = combineReducers({schedule, event, filter, modals, error})

export type State = ReturnType<typeof rootReducer>
