import {createReducer} from '@reduxjs/toolkit'
import {toggleScheduleItemModal, toggleFiltersModal} from '../actions/modals'
import {ScheduleItem} from '../types'

const scheduleItemModal: ScheduleItemModalState = {
  open: false,
  item: null,
}

const filtersModal: FiltersModalState = {
  open: false,
}

const initialState: ModalsState = {
  scheduleItemModal,
  filtersModal,
}

export const modals = createReducer(initialState, builder => {
  builder.addCase(toggleScheduleItemModal, (state, action) => {
    state.scheduleItemModal = {
      open: !state.scheduleItemModal.open,
      item: action.payload,
    }
  })
  builder.addCase(toggleFiltersModal, state => {
    state.filtersModal = {
      open: !state.filtersModal.open,
    }
  })
})

interface FiltersModalState {
  open: boolean
}

interface ScheduleItemModalState {
  open: boolean
  item: ScheduleItem
}

export interface ModalsState {
  scheduleItemModal: ScheduleItemModalState
  filtersModal: FiltersModalState
}
