import {createReducer} from '@reduxjs/toolkit'
import {getSchedule, getDemoSchedule} from '../actions/schedule'
import {ScheduleItemResponse, ScheduleResponse} from '../types'

const initialState: ScheduleState = {
  items: [],
  total: 0,
  availableLocations: [],
  availableTags: [],
}

export const schedule = createReducer(initialState, builder => {
  builder
    .addCase(getSchedule.fulfilled, (state, action) => updateStateWithSchedule(state, action.payload))
    .addCase(getDemoSchedule.fulfilled, (state, action) => updateStateWithSchedule(state, action.payload))
})

const updateStateWithSchedule = (state: ScheduleState, {items, total, facets}: ScheduleResponse) => {
  if (!state.total) {
    state.total = total
  }

  if (!state.availableLocations.length && !state.availableTags.length) {
    state.availableLocations = Object.keys(facets.stageName.counts)
    state.availableTags = Object.keys(facets.tag.counts)
  }

  state.items = items
}

export interface ScheduleState {
  items: ScheduleItemResponse[]
  total: number
  availableLocations: string[]
  availableTags: string[]
}
